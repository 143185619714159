import axios from '@/plugins/axios';

const notificationRequests = {
    async getNotifications(page) {
        try {
            const response = await axios.get(`/notifications?page=${page}`);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async seenState() {
        try {
            const response = await axios.post('notifications/seen-state');
            return response;
        } catch (error) {
            throw error;
        }
    },

    async markNotificationAsRead(notification_id) {
        try {
            const response = await axios.post('notifications/mark-as-read', {
                notification_id: notification_id 
            });
            return response;
        } catch (error) {
            throw error;
        }
    },

    async getNotification(id) {
        try {
            const response = await axios.get(`notifications/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    },

    async markAllNotificationAsRead() {
        try {
            const response = await axios.post(`notifications/mark-all-as-read`);
            return response;
        } catch (error) {
            throw error;
        }
    }
};

export default notificationRequests;