<template>
    <div class="notification-details p-4">
        <div v-if="isLoading" class="flex items-center justify-center h-full w-full">
            <!--<div class="fulfilling-square-spinner">
              <div class="spinner-inner"></div>
            </div>-->
            <div class="loading">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>
        <div class="notification-details-wrapper p-4" v-if="notification && !isLoading">
            <div class="notification-details-section p-2">
                <div class="notification-details-section__item">
                    <p>Titre:</p>
                    <p>{{ notification.title }}</p>
                </div>
                <div class="notification-details-section__item">
                    <p>Date: </p>
                    <p>{{ notification.created_at }}</p>
                </div>
            </div>
            <div class="notification-details-section p-2">
                <p class="title">Détails du rapport</p>
                <ul>
                    <li>
                        <div class="notification-details-section__item">
                            <p>Question:</p>
                            <p class=" gap-4">
                                <span class="block">{{ notification.report.question }}</span>
                                <span @click="showQuestionModal = true"
                                    class="block w-6 transform hover:text-red-400 hover:scale-110">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg>
                                </span>
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="notification-details-section__item">
                            <p>Type de rapport:</p>
                            <p>{{ notification.report.type }}</p>
                        </div>

                    </li>
                    <li>
                        <div class="notification-details-section__item">
                            <p>Votre commentaire:</p>
                            <p>{{ notification.report.comment }}</p>
                        </div>

                    </li>
                    <li>
                        <div class="notification-details-section__item">
                            <p>Date du rapport:</p>
                            <p>{{ notification.report.date }}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="notification-details-section p-2">
                <p class="title">Réponse de l'administrateur</p>
                <ul>
                    <li>
                        <div class="notification-details-section__item">
                            <p>Message de réponse:</p>
                            <p>{{ notification.report.response }}</p>
                        </div>
                    </li>
                    <li>
                        <div class="notification-details-section__item">
                            <p>Date de la réponse:</p>
                            <p>{{ notification.created_at }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <ShowModal v-if="showQuestionModal" :closeShowQuestion="closeShowQuestion"
            :selectedQuestion="selectedQuestion" />
    </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import notificationRequests from '../../services/requests/notification';
import { useRoute } from 'vue-router';
import ShowModal from './QuestionsModals/ShowModal.vue';
export default {
    components: {
        ShowModal
    },
    props: {
        linkPath: Function,
    },
    setup(props) {
        const currentRoute = ref('/dashboard/notifications/:id');
        const route = useRoute();
        const notification = ref({});
        const isLoading = ref(false);
        const showQuestionModal = ref(false);
        const selectedQuestion = ref({
            id: null,
            type: null,
        })
        async function getNotification() {
            isLoading.value = true;
            let id = route.params.id;

            try {

                const response = await notificationRequests.getNotification(id);
                if (response) {
                    notification.value = response.data.data;
                    selectedQuestion.value.id = response.data.data.report.question_id;
                    selectedQuestion.value.type = response.data.data.report.question_type;
                }
            } catch (error) {

            } finally {
                isLoading.value = false;
            }
        }

        function closeShowQuestion() {
            showQuestionModal.value = false;
        }

        // Watch for changes in the route params
        watch(
            () => route.params.id,
            (newNotifId) => {
                if (newNotifId) {
                    getNotification();
                }
            },
            { immediate: true }
        );

        onMounted(() => {
            props.linkPath(currentRoute.value);
            /*if (route.params.id) {
                getNotification();
            }*/
        })

        return {
            notification,
            getNotification,
            isLoading,
            showQuestionModal,
            selectedQuestion,
            closeShowQuestion
        }
    }
}
</script>

<style lang="scss" scoped></style>